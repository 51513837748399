import React from 'react'

import PropTypes from 'prop-types'

import './hero8.css'

const Hero8 = (props) => {
  return (
    <div className="hero8-header26 thq-section-padding">
      <div className="hero8-max-width thq-section-max-width thq-flex-column">
        
        <div className="hero8-column">
          <div className="hero8-content">
            <h2 className="thq-heading-2">Cancel the Trip</h2>
           {/* <p className="hero8-text1 thq-body-large">{props.content1}</p>*/ }
            {/*<div className="hero8-actions">
              <button className="thq-button-filled hero8-button">
                <span className="thq-body-small">{props.action1}</span>
              </button>
              <button className="thq-button-outline hero8-button1">
                <span className="thq-body-small">{props.action2}</span>
              </button>
            </div>  */}
          </div>
        </div> 
        
      </div>
    </div>
  )
}

Hero8.defaultProps = {
  content1:
    'Explore, contribute, and filter promises made by individuals and organizations',
  image1Src:
    'https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDE2OTMxNnw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Join the Promise Platform',
  action1: 'Learn more',
  action2: 'Sign up now',
  image1Alt: 'Illustration of diverse group of people making promises',
}

Hero8.propTypes = {
  content1: PropTypes.string,
  image1Src: PropTypes.string,
  heading1: PropTypes.string,
  action1: PropTypes.string,
  action2: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default Hero8
