import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './features11.css';

const FeaturedTrips = (props) => {
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    fetch('https://api.promiselogs.org/featured_trips')
      .then(response => response.json())
      .then(data => setTrips(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="features11-layout251 thq-section-padding">
      <div className="features11-max-width thq-section-max-width">
        <div className="thq-flex-row features11-section-title">
          <div className="features11-column thq-flex-column">
            <h2 className="thq-heading-2 features11-text1">
             President Ruto's International trips
            </h2>
          </div>
        </div>
        <div className="features11-content">
          <div className="features11-row thq-flex-row">
            
            {trips.map((trip, index) => (
                <a href={`/trips/${trip.trip_slug}`}>
              <div key={index} className={`features11-feature11 thq-flex-column`}>
                <img
                  alt={trip.trip_title}
                  src={`https://promiselogs.org/assets/images/trips/${trip.trip_avatar}`}
                  className="thq-img-ratio-4-3 features11-feature-image"
                />
                <div className={`features11-content1 thq-flex-column`}>
                  <h3 className="thq-heading-3">{trip.trip_title}</h3>
                  <span className="thq-body-small">
                    {trip.trip_dates} | {trip.city}, {trip.country}
                  </span>
                </div>
              </div>
              </a>
            ))}
            
          </div>
        </div>
      </div>
    </div>
  );
};


export default FeaturedTrips
