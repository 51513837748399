import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero.css'

const Hero = (props) => {
  return (
    <div className="hero-header78">
      <div className="hero-column thq-section-padding thq-section-max-width">
        <div className="hero-content">
          <h1 className="hero-text thq-heading-1">{props.heading1}</h1>
          <p className="hero-text1 thq-body-large">{props.content1}</p>
        </div>
        <div className="hero-actions">
          <button className="thq-button-filled hero-button">
            <span className="thq-body-small">Explore promises</span>
          </button>
          <button className="thq-button-outline hero-button1">
            <span className="thq-body-small">{props.action2}</span>
          </button>
        </div>
      </div>
      <div className="hero-content1">
        <div className="hero-row-container thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero-placeholder-image thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero-placeholder-image01 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero-placeholder-image02 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero-placeholder-image03 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero-placeholder-image04 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="hero-placeholder-image05 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero-placeholder-image06 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero-placeholder-image07 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero-placeholder-image08 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero-placeholder-image09 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
        <div className="hero-row-container1 thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image12Alt}
              src={props.image12Src}
              className="hero-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero-placeholder-image23 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero-container1">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = {
  image4Src:
    '/assets/images/agriculture.jpg',
  image2Src:
    '/assets/images/health.jpg',
  image1Alt: 'Promise Listing Image',
  image12Src:
    '/assets/images/manufacturing.jpeg',
  image3Alt: 'Crowdsourcing Form Image',
  image8Alt: 'Black and White Minimalistic Design Image',
  image4Alt: 'Sign Up with Google Image',
  image1Src:
    '/assets/images/mining.webp',
  image12Alt: 'Share Your Promise Image',
  image8Src:
    '/assets/images/transport.jpg',
  image3Src:
    '/assets/images/education.png',
  image10Alt: 'Promises Made Image',
  image7Alt: 'Filter Promises by Specific Promisers Image',
  image6Src:
    '/assets/images/IT.jpeg',
  image9Src:
    '/assets/images/estate.jpg',
  image7Src:
    '/assets/images/water.jpg',
  image9Alt: 'References and Citations Image',
  image11Src:
    '/assets/images/youth.jpg',
  action2: 'Learn More',
  image5Src:
    '/assets/images/women.jpeg',
  image6Alt: 'Filter Promises by Industry Sector Image',
  image11Alt: 'Join the Community Image',
  image10Src:
    '/assets/images/pwd.jpg',
  content1:
    'Promise is a debt and every word that comes from civil servant mouth becomes our business.We log and track every promise made by Kenyan government officials. Explore, list and filter promises by regions, industry sectors, and other criteria.',
  image2Alt: 'Explore Promises Image',
  image5Alt: 'Filter Promises by Region Image',
  action1: 'Get Started',
  heading1: 'Welcome to Promiselogs',
}

Hero.propTypes = {
  image4Src: PropTypes.string,
  image2Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image12Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image8Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image12Alt: PropTypes.string,
  image8Src: PropTypes.string,
  image3Src: PropTypes.string,
  image10Alt: PropTypes.string,
  image7Alt: PropTypes.string,
  image6Src: PropTypes.string,
  image9Src: PropTypes.string,
  image7Src: PropTypes.string,
  image9Alt: PropTypes.string,
  image11Src: PropTypes.string,
  action2: PropTypes.string,
  image5Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image11Alt: PropTypes.string,
  image10Src: PropTypes.string,
  content1: PropTypes.string,
  image2Alt: PropTypes.string,
  image5Alt: PropTypes.string,
  action1: PropTypes.string,
  heading1: PropTypes.string,
}

export default Hero
