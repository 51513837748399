import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Navbar from '../components/navbar';
import MPHeader from '../components/mpHeader';
import MpBody from '../components/mpBody';
import RelatedMps from '../components/releatedMps';
import Footer from '../components/footer';
import './about.css';
import preloaderGif from './preloader.gif'; // Ensure the path to your preloader gif is correct

const Mp = (props) => {
  const { slug } = useParams();
  const [mpData, setMpData] = useState(null);
  const [loading, setLoading] = useState(true); // New state for tracking loading status

  useEffect(() => {
    const fetchmpData = async () => {
      setLoading(true); // Start loading before fetching data
      try {
        const response = await fetch(`https://api.promiselogs.org/national_assembly/members/${slug}`);
        const data = await response.json();
        setMpData(data);
      } catch (error) {
        console.error('Error fetching MP data:', error);
      } finally {
        setLoading(false); // End loading after fetching data
      }
    };

    fetchmpData();
  }, [slug]);
/*
const Mp = (props) => {
  const { slug } = useParams();
  const [mpData, setMpData] = useState(null);

  useEffect(() => {
    const fetchmpData = async () => {
      try {
        const response = await fetch(`https://api.promiselogs.org/national_assembly/members/${slug}`);
        const data = await response.json();
        setMpData(data);
        // console.log(data)
      } catch (error) {
        console.error('Error fetching MP data:', error);
      }
    };

    fetchmpData();
  }, [slug]);

  */

  return (
    <div className="about-container">
      <Helmet>
        <title>About {mpData ? mpData.mp_info[0].name : ' - Promiselogs'} - Promiselogs</title>
        <meta name="description" content={mpData && mpData.mp_info && mpData.mp_info[0] ? `${mpData.mp_info[0].name} is the current member of parliament representing ${mpData.mp_info[0].represents} constituency. The legislator was elected as ${mpData.mp_info[0].party} candidate and holds a ${mpData.mp_info[0].education}` : 'Loading legislator information...'} />
        <meta property="og:title" content={mpData && mpData.mp_info && mpData.mp_info[0] ? `About ${mpData.mp_info[0].name} - Promiselogs` : 'About - Promiselogs'} />
        <meta property="og:description" content={mpData && mpData.mp_info && mpData.mp_info[0] ? `${mpData.mp_info[0].name} is the current member of parliament representing ${mpData.mp_info[0].represents} constituency. The legislator was elected as ${mpData.mp_info[0].party} candidate and holds a ${mpData.mp_info[0].education}` : 'Loading legislator information...'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://promiselogs.org/mp/${mpData && mpData.mp_info && mpData.mp_info[0] ? encodeURIComponent(mpData.mp_info[0].slug) : ''}`} />
        <meta property="og:image" content={mpData && mpData.mp_info && mpData.mp_info[0] ? `https://promiselogs.org/assets/images/${mpData.mp_info[0].avatar}` : 'https://promiselogs.org/assets/images/default-mp.jpg'} />
        <meta property="og:image:height" content="1637" />
        <meta property="og:image:width" content="2560" />

  <meta name="twitter:card" content="summary" />
  <meta name="twitter:url" content={`https://promiselogs.org/mp/${mpData && mpData.mp_info && mpData.mp_info[0] ? encodeURIComponent(mpData.mp_info[0].slug) : ''}`} />
  <meta
    name="twitter:title"
    content={mpData && mpData.mp_info && mpData.mp_info[0] ? `About ${mpData.mp_info[0].name} - Promiselogs` : 'About - Promiselogs'}   />
  <meta
    name="twitter:description"
    content={mpData && mpData.mp_info && mpData.mp_info[0] ? `${mpData.mp_info[0].name} is the current member of parliament representing ${mpData.mp_info[0].represents} constituency. The legislator was elected as ${mpData.mp_info[0].party} candidate and holds a ${mpData.mp_info[0].education}` : 'Loading legislator information...'}  />
  <meta
    name="twitter:image:src"
    content={mpData && mpData.mp_info && mpData.mp_info[0] ? `https://promiselogs.org/assets/images/${mpData.mp_info[0].avatar}` : 'https://promiselogs.org/assets/images/default-mp.jpg'}
  />
  <meta name="twitter:image:width" content={2560} />
  <meta name="twitter:image:height" content={1637} />
        </Helmet>
      <Navbar />
      
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <img src={preloaderGif} alt="Loading..." />
        </div>
      ) : (
        mpData && mpData.mp_info && mpData.mp_info[0] && (
          <>
            <MPHeader mpInfo={mpData.mp_info[0]} />
            <MpBody mpInfo={mpData.mp_info[0]} />
            <RelatedMps relatedWith={mpData.related_with} />
            
          </>
        )
      )}
      <Footer />
    </div>
  );
};

export default Mp;
