import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/navbar';
import Hero from '../components/hero';
import Features1 from '../components/features1';
import CTA from '../components/cta';
import Features2 from '../components/features2';
import Pricing from '../components/pricing';
import Steps from '../components/steps';
import Testimonial from '../components/testimonial';
import Contact from '../components/contact'
import Features11 from '../components/featuredTrips'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  const [appName, setAppName] = useState('Promiselogs');

  useEffect(() => {
    fetch('https://api.promiselogs.org/api/info')
      .then(response => response.json())
      .then(data => {
        setAppName(data.app_name);
      })
      .catch(error => {
        console.error('Error fetching app name:', error);
      });
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>The Kenya Kwanza Government Promise Tracker - {appName} </title>
        <meta name="description" content="Promiselogs is a Kenyan public accountability tool that lists and tracks all promises made by current and past regimes in both National and devolution governments" />
        <meta property="og:title" content={appName} />
        <meta property="og:description" content="Promiselogs is a Kenyan public accountability tool that lists and tracks all promises made by current and past regimes in both National and devolution governments" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://promiselogs.org" />
        <meta property="og:image" content="https://promiselogs.org/assets/images/William-Ruto-during-Kenya-Kwanza-manifesto-launch.jpg" />
        <meta property="og:image:height" content="1637" />
        <meta property="og:image:width" content="2560" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://promiselogs.org" />
        <meta
          name="twitter:title"
          content="The Kenya Kwanza Government Promise Tracker - Promiselogs"
        />
        <meta
          name="twitter:description"
          content="Promiselogs is a Kenyan public accountability tool that lists and tracks all promises made by current and past regimes in both National and devolution governments."
        />
        <meta
          name="twitter:image:src"
          content="https://promiselogs.org/assets/images/William-Ruto-during-Kenya-Kwanza-manifesto-launch.jpg"
        />
        <meta name="twitter:image:width" content={2560} />
        <meta name="twitter:image:height" content={1637} />

      </Helmet>
      <Navbar></Navbar>
      <Hero></Hero>
      <Features1></Features1>
      <CTA></CTA>
      <Features2></Features2>
      {/*<Pricing></Pricing> */}
      <Steps></Steps>
      {/* <Testimonial></Testimonial> */}
      {/*<Contact></Contact> */}
      <Features11></Features11>
      <Footer></Footer>
    </div>
  );
}

export default Home;
