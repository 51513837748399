import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ContactForm3 from '../components/contact-form3'
import Contact14 from '../components/contact14'
import Contact7 from '../components/contact7'
import Footer from '../components/footer'
import './contact1.css'

const Contact1 = (props) => {
  return (
    <div className="contact1-container">
      <Helmet>
        <title>Contact1 - Promiselogs</title>
        <meta
          property="og:title"
          content="Contact1 - Spotless Hungry Crocodile"
        />
      </Helmet>
      <Navbar></Navbar>
      <ContactForm3></ContactForm3>
      <Contact14></Contact14>
      <Contact7></Contact7>
      <Footer></Footer>
    </div>
  )
}

export default Contact1
