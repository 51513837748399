import React from 'react'

import PropTypes from 'prop-types'

import './features11.css'

const RelatedTrips = ({ relatedWith}) => {
  return (
    <div className="features11-layout251 thq-section-padding">
      <div className="features11-max-width thq-section-max-width">
        <div className="thq-flex-row features11-section-title">
          <div className="features11-column thq-flex-column">
            
            <h3 className="thq-heading-3 features11-text1">
              Related Trips
            </h3>
          </div>
         
        </div>
        <div className="features11-content">
          <div className="features11-row thq-flex-row">
            <div className="features11-feature1 thq-flex-column">
            <a href={`/trips/${relatedWith[0].trip_slug}`}>
              <img
                alt={ relatedWith[0].trip_title}
                src={`https://promiselogs.org/assets/images/trips/${relatedWith[0].trip_avatar}`}
                className="thq-img-ratio-4-3 features11-feature1-image"
              />
              <div className="features11-content1 thq-flex-column">
                <h4 className="thq-heading-4">{ relatedWith[0].trip_title}</h4>
                <span className="thq-body-small">
                {relatedWith[0].trip_dates}  {` || ${relatedWith[0].city}, `} {relatedWith[0].country}
                </span>
              </div>
              </a>
            </div>
            <div className="features11-feature2 thq-flex-column">
            <a href={`/trips/${relatedWith[1].trip_slug}`}>
              <img
                alt={ relatedWith[1].trip_title}
                src={`https://promiselogs.org/assets/images/trips/${relatedWith[1].trip_avatar}`}
                className="thq-img-ratio-4-3 features11-feature2-image"
              />
              <div className="features11-content2 thq-flex-column">
                <h4 className="thq-heading-4">{ relatedWith[1].trip_title}</h4>
                <span className="thq-body-small">
                {relatedWith[1].trip_dates}  {` || ${relatedWith[1].city}, `}{relatedWith[1].country}
                </span>
              </div>
              </a>
            </div>
            <div className="features11-feature3 thq-flex-column">
                <a href={`/trips/${relatedWith[2].trip_slug}`}>
              <img
                alt={ relatedWith[2].trip_title}
                src={`https://promiselogs.org/assets/images/trips/${relatedWith[2].trip_avatar}`}
                className="thq-img-ratio-4-3 features11-feature3-image"
              />
              <div className="features11-content3 thq-flex-column">
                <h4 className="thq-heading-4">{ relatedWith[2].trip_title}</h4>
                <span className="thq-body-small">
                {relatedWith[2].trip_dates}  {` || ${relatedWith[2].city}, `} {relatedWith[2].country}
                </span>
              </div>
              </a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}



export default RelatedTrips
