import React from 'react'

import PropTypes from 'prop-types'

import './steps.css'

const Steps = (props) => {
  return (
    <div className="steps-container thq-section-padding">
      <div className="steps-max-width thq-section-max-width">
        <div className="steps-container1 thq-grid-2">
          <div className="steps-section-header">
            <h2 className="thq-heading-2">
              The power of Crowdsourcing data
            </h2>
            <p className="thq-body-large">
             Since the onset of Finance Bill 2024 protests in Kenya, the public has been able to identify rogue cops, list legislators contacts as well as busiesses associated
             with them and much more through crowdsourcing data on Social media. This platform has been created to track all promises made by goverment officials, Bills passed in parliaments, how MPs vote for bills in both houses,
             extrajudicial killigs among other topics and informatio of general public interests. You many tag us on Twitter, send email, chat our Telegram bot or fill online form to list,edit or update a promise, bill or any other resource on
               <strong> Promiselogs</strong>. Be keen to include context and references where applicable.
            </p>
            <div className="steps-actions">
              <button className="thq-button-animated thq-button-filled steps-button">
                <span className="thq-body-small">Learn more</span>
              </button>
            </div>
          </div>
          <div className="steps-container2">
            <div className="steps-container3 thq-card">
              <h2 className="thq-heading-2">Telegram</h2>
              <span className="steps-text04 thq-body-small">
               You may contribute information by engaging @PromiselogsBot on Telegram app.
              </span>
              <label className="steps-text05 thq-heading-3">01</label>
            </div>
            <div className="steps-container4 thq-card">
              <h2 className="thq-heading-2">Email</h2>
              <span className="steps-text07 thq-body-small">
                You can also contribute/edit information by sendig a email to promise@promiselogs.org
              </span>
              <label className="steps-text08 thq-heading-3">02</label>
            </div>
            <div className="steps-container5 thq-card">
              <h2 className="thq-heading-2">Twitter</h2>
              <span className="steps-text10 thq-body-small">
                Tag @promiselogs on Twitter under any tweet whose contents are related to promises, parliametary bills, extrajudicial killings, public debt or any other relevant content. Our system will pull the data and create a draft immediately.
              </span>
              <label className="steps-text11 thq-heading-3">03</label>
            </div>
            <div className="steps-container6 thq-card">
              <h2 className="thq-heading-2">Context & References</h2>
              <span className="steps-text13 thq-body-small">
                We expect huge volumes of both spam and content submissions for indexing/listing. As a result, only submissions with clear context and references/evidence will be prioritized.
              </span>
              <label className="steps-text14 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps.defaultProps = {
  step3Title: 'Filter Promises',
  step4Title: 'Contribute to Crowdsourcing',
  step2Description:
    'Share a promise made by a person or organization, including details such as who made the promise, what it entails, and when it was made.',
  step4Description:
    'Join the community in verifying promises and adding references to ensure transparency and credibility.',
  step1Title: 'Sign Up with Google',
  step2Title: 'Submit a Promise',
  step3Description:
    'Easily search and filter promises based on regions, industry sectors, or specific promisers.',
  step1Description:
    'Create an account or sign in using your Google account to access all features.',
}

Steps.propTypes = {
  step3Title: PropTypes.string,
  step4Title: PropTypes.string,
  step2Description: PropTypes.string,
  step4Description: PropTypes.string,
  step1Title: PropTypes.string,
  step2Title: PropTypes.string,
  step3Description: PropTypes.string,
  step1Description: PropTypes.string,
}

export default Steps
