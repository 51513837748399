import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

// If you need these, un-comment them or import as required
// import Navbar from '../components/navbar'
 import Hero8 from '../components/hero8'
// import Stats2 from '../components/stats2'
// import Features11 from '../components/features11'
// import Footer from '../components/footer'
import './about.css'

const Wanking = (props) => {
  const [name, setName] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name.trim()) {
      alert('Please enter your name')
      return
    }

    const email = 'rvd.pers.kh@minaz.nl'
    const subject = 'Request to Cancel State Visit to Kenya'
    const body = `My name is ${name}, a concerned Citizen of the Republic of Kenya. I am writing to you on behalf of millions of fellow Kenyans, asking you to cancel your scheduled state visit to Kenya in March, 2025 for the following reason(s):

1) President Ruto's regime shot dead hundreds of innocent protestors last year on June 25, 2024 live on camera. The protestors were demonstrating against bad governance. [https://x.com/LarryMadowo/status/1807699601291415678].

2) President Ruto's regime has made Kenya an hotbed of abductions and forced disappearances. The kidnappings not only includes ordinary Kenyan citizens but also foreign high profile opposition leaders and journalists seeking asylum in Kenya. [https://x.com/LuluHassan/status/1878419972679987635]

3) President Ruto's regime is plotting to rig the next general elections scheduled for Aug 2027. [https://x.com/ntvkenya/status/1878386268087697718]

Your visit to Kenya will mean that His Majesty King Willem Alexander and Netherlands in general supports the injustices perpetrated by William Ruto and his tyrant regime.

Moreover, the safety of His Majesty King Willem Alexander is not guaranteed as Kenyans are planning a mega protest which might turn violent over that period.

Yours,

${name}
`

    // Construct the mailto link and open the user’s email client
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    window.location.href = mailtoLink
  }

  return (
    <div className="about-container">
      <Helmet>
        <title>Digital Wanking - Promiselogs</title>
        <meta property="og:title" content="Digital Wanking - Promiselogs" />
      </Helmet>

      {/* If you use these components, uncomment them */}
      {/* <Navbar /> */}
      { <Hero8 /> }
      {/* <Stats2 /> */}
      {/* <Features11 /> */}
      {/* <Footer /> */}

      <center>
        <form className="footer-actions" onSubmit={handleSubmit}>
          <div className="footer-form">
            <div className="footer-container">
              <input
                type="text"
                placeholder="Enter your name(for signing off)"
                className="footer-text-input thq-input"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <button type="submit" className="thq-button-outline footer-button">
              <span className="thq-body-small">Send Email</span>
            </button>
          </div>
        </form>
      </center>
    </div>
  )
}

export default Wanking
