import React from 'react'

import PropTypes from 'prop-types'

import './features11.css'
function formatText(text) {
    return text
      .split('-') // Split the string at each hyphen
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with spaces
  }

const RelatedMps = ({ relatedWith}) => {
  return (
    <div className="features11-layout251 thq-section-padding">
      <div className="features11-max-width thq-section-max-width">
        <div className="thq-flex-row features11-section-title">
          <div className="features11-column thq-flex-column">
            
            <h3 className="thq-heading-3 features11-text1">
              Related MPs
            </h3>
          </div>
         
        </div>
        <div className="features11-content">
          <div className="features11-row thq-flex-row">
            <div className="features11-feature1 thq-flex-column">
            <a href={`/mp/${relatedWith[0].slug}`}>
              <img
                alt={ relatedWith[0].name}
                src={`https://promiselogs.org/assets/images/${relatedWith[0].avatar}`}
                className="thq-img-ratio-4-3 features11-feature1-image"
              />
              <div className="features11-content1 thq-flex-column">
                <h4 className="thq-heading-4">{ relatedWith[0].name}</h4>
                <span className="thq-body-small">
                {formatText(relatedWith[0].represents)} Constituency
                </span>
              </div>
              </a>
            </div>
            <div className="features11-feature2 thq-flex-column">
            <a href={`/mp/${relatedWith[1].slug}`}>
              <img
                alt={ relatedWith[1].name}
                src={`https://promiselogs.org/assets/images/${relatedWith[1].avatar}`}
                className="thq-img-ratio-4-3 features11-feature2-image"
              />
              <div className="features11-content2 thq-flex-column">
                <h4 className="thq-heading-4">{ relatedWith[1].name}</h4>
                <span className="thq-body-small">
                {formatText(relatedWith[1].represents)} Constituency
                </span>
              </div>
              </a>
            </div>
            <div className="features11-feature3 thq-flex-column">
                <a href={`/mp/${relatedWith[2].slug}`}>
              <img
                alt={ relatedWith[2].name}
                src={`https://promiselogs.org/assets/images/${relatedWith[2].avatar}`}
                className="thq-img-ratio-4-3 features11-feature3-image"
              />
              <div className="features11-content3 thq-flex-column">
                <h4 className="thq-heading-4">{ relatedWith[2].name}</h4>
                <span className="thq-body-small">
                {formatText(relatedWith[2].represents)} Constituency
                </span>
              </div>
              </a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}



export default RelatedMps
